import React, { useState, useEffect, useCallback, useMemo, createContext, useContext } from "react"

import useSessionStorage from "hooks/useSessionStorage"
import { useLocation } from "store/contexts/location"
import { identifyUser } from "utils/identifyUser"

const UserContext = createContext(null)

const fetchUser = async () => {
  const response = await fetch("/api/users/status")

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }

  return response.json()
}

export const UserProvider = ({ children }) => {
  const { location } = useLocation()
  const [user, setUser] = useSessionStorage("user", { id: null, email: null, loggedIn: false })
  const [checkedForUser, setCheckedForUser] = useSessionStorage("checkedForUser", false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")

  const getUser = useCallback(async () => {
    setLoading(true)
    setError("")

    try {
      const data = await fetchUser()
      setUser({ id: data.userId, email: data.user.email, loggedIn: true })
    } catch (err) {
      setUser({ id: null, email: null, loggedIn: false })
      setError(err.message)
    }

    setCheckedForUser(true)
    setLoading(false)
  }, [setUser, setCheckedForUser])

  const clearUser = useCallback(() => {
    setUser({ id: null, email: null, loggedIn: false })
    setCheckedForUser(false)
  }, [setUser, setCheckedForUser])

  useEffect(() => {
    if (
      !checkedForUser &&
      !["/account/callback/login", "/account/callback/logout"].includes(location.pathname)
    ) {
      getUser()
    }
  }, [location, getUser, checkedForUser])

  useEffect(() => {
    if (user.email) {
      identifyUser({ user })
    }
  }, [user])

  useEffect(() => {
    // push logged in data layer for Permutive
    window.pDataLayer = window.pDataLayer || {}
    window.pDataLayer.user = { loggedIn: user.loggedIn }
  }, [user.loggedIn])

  return (
    <UserContext.Provider
      value={useMemo(
        () => ({
          user,
          getUser,
          clearUser,
          loading,
          error,
        }),
        [user, getUser, clearUser, loading, error],
      )}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}
