import React, { useMemo, createContext, useContext } from "react"

export const ComponentizedPageContext = createContext({})

export const ComponentizedPageProvider = ({ ...props }) => {
  const value = useMemo(
    () => ({
      class: null,
      product: null,
      pageId: null,
      template: "Standard",
      pageTheme: {},
      classesOnPage: [],
      classBundles: [],
    }),
    [],
  )

  return <ComponentizedPageContext.Provider value={value} {...props} />
}

export const useComponentizedPage = () => {
  const context = useContext(ComponentizedPageContext)
  if (!context) {
    throw new Error(`useComponentizedPage must be used within a ComponentizedPageProvider`)
  }
  return context
}
