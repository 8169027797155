const appTheme = {
  colors: {
    background: {
      page: "#ffffff",
      nav: "#ffffff",
      footer: "#003445",
    },
    text: {
      category: "#5458a8",
      primary: "#222222",
      alternate: "#ffffff",
    },
    classesTeal: "#00a3b7",
    productsOrange: "#d24715",
    purpleMindfulness: "#6058bc",
    supplements: "#d24715",
    podcasts: "#36A58A",
    astrology: "#5458a8",
    fnp: {
      ff: "#b20036",
      hwf: "#657d48",
      nc: "#4a0527",
    },
    categories: {
      mindfulness: "#6058bc",
      health: "#c14e78",
      food: "#7D5BAE",
      movement: "#A35CA2",
      beauty: "#C14E78",
      home: "#7D5BAE",
      parenting: "#CA4955",
      planet: "#378358",
      relationships: "#CA4955",
      lifestyle: "#4961BD",
    },
    checkout: {
      white: "#FFFFFF",
      black: "#222222",
      secondaryBackground: "#EFEEE9",
      lightBackground: "#F3F3F3",
      darkBackground: "#003445",
      primaryText: "#003445",
      secondaryText: "#333333",
      text: "#002F30",
      buttonPrimary: "#D34612",
      buttonPrimary200: "#D04010",
      border: "#003445",
      borderLight: "#E0E0E0",
      borderDarker: "#BBBBBB",
      borderFocus: "#a9a9a9",
      grey: "#767676",
      placeholder: "#757575",
      errorContainer: "#f8d7da",
      errorBorder: "#f5c6cb",
      errorText: "#721c24",
      checkoutBorderLight: "#CCCCCC",
    },
    categoryGradients: {
      mindfulness: "linear-gradient(to bottom, #6058bc, #303091)",
      health: "linear-gradient(to bottom, #c14e78, #a35ca2)",
      food: "linear-gradient(to bottom, #7D5BAE, #6058BC)",
      movement: "linear-gradient(to bottom, #A35CA2, #7D5BAE)",
      beauty: "linear-gradient(to bottom, #C14E78, #A35CA2)",
      home: "linear-gradient(to bottom, #7D5BAE, #6058BC)",
      parenting: "linear-gradient(to bottom, #CA4955, #C14E78)",
      planet: "linear-gradient(to bottom, #378358, #00A3B7)",
      relationships: "linear-gradient(to bottom, #CA4955, #C14E78)",
      lifestyle: "linear-gradient(to bottom, #4961BD, #303091)",
    },
    webinar: {
      button: {
        primary: "#D34612",
        primaryHover: "#D34612",
        primaryDarker: "#B12500",
        primaryText: "#f5f9fff2",
        red: "#C74E5B",
        redHover: "#FFB2B6",
        iconHover: "#293042",
        iconOnlyHover: "#8F9099",
        disabled: "#bbb",
        disabledText: "#fff",
      },
      inputBackground: "#f6f7f9",
      inputBorder: "#bcc4d2",
      inputText: "#222222",
      chatBackground: "#efeee9",
      chatHeading: "#000000",
      chatText: "#171718",
      chatTextMessage: "#000000d5",
      chatSenderName: "#D34612",
      chatInputBackground: "rgb(252, 251, 251)",
      chatInputBackgroundMobile: "rgba(239, 238, 233, .8)",
      chatScrollbar: "#bab8ad",
      chatScrollbarHover: "#999999",
      chatInputFocus: "rgba(211,70,18,0.8)",
    },
  },
  cartTitles: {
    products: "shop",
    classes: "classes",
    calmProducts: "calm",
  },
  navLogos: {
    home: {
      color: "#222",
      text: "",
    },
    classes: {
      color: "#00a3b7",
      text: "Classes",
    },
    checkout: {
      color: "#222",
      text: "",
    },
    astro: {
      color: "#5458a8",
      text: "astrology",
    },
    horoscope: {
      color: "#5458a8",
      text: "astrology",
    },
    lifestyle: {
      color: "#4961BD",
      text: "Lifestyle",
    },
    health: {
      color: "#C14E78",
      text: "Health",
    },
    mindfulness: {
      color: "#6058BC",
      text: "Mindfulness",
    },
    movement: {
      color: "#A35CA2",
      text: "Movement",
    },
    food: {
      color: "#7D5BAE",
      text: "food",
    },
    relationships: {
      color: "#CA4955",
      text: "Relationships",
    },
    planet: {
      color: "#378358",
      text: "planet",
    },
    supplements: {
      color: "#d24715",
      text: "Shop",
    },
    revitalize: {
      color: "#1262B2",
      text: "revitalize",
    },
  },
  breakpoints: {
    largest: 1440,
    desktopXL: 1300,
    desktopL: 1200,
    rightRail: 1100,
    hamburgerLarge: 1024,
    hamburgerSmall: 1023,
    desktop: 992,
    tablet: 768,
    tabletLow: 767,
    mobile: 576,
    mobileM: 375,
    mobileS: 320,
  },
  deviceBreakpoints: {
    tablet: { min: 768, max: 1024 },
  },
  contentfulTemplates: {
    "Standard": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#222222",
      "Dark Background Secondary": "#222222",
      "Primary CTA": "#222222",
      "Primary CTA Inverted": "#ffffff",
      "Category Color": "#222222",
      "Highlight Color": "#c45327",
    },
    "Classes": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F0DFD1",
      "Dark Background Primary": "#006470",
      "Dark Background Secondary": "#00A3B7",
      "Primary CTA": "#D815DE",
      "Category Color": "#00A3B7",
      "Highlight Color": "#00A3B7",
    },
    "Category - Lifestyle": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#4961BD",
      "Dark Background Secondary": "#4961BD",
      "Primary CTA": "#4961BD",
      "Category Color": "#4961BD",
      "Highlight Color": "#4961BD",
    },
    "Functional Nutrition Program": {
      "sectionHeading": "Type A",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#222222",
      "Dark Background Secondary": "#222222",
      "Primary CTA": "#222222",
      "Category Color": "#222222",
      "Highlight Color": "#222222",
    },
    "Supplements": {
      "sectionHeading": "Type B",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#FCF7F2",
      "Light Background Secondary": "#F6E9DD",
      "Dark Background Primary": "#6E4238",
      "Dark Background Secondary": "#7FA3CD",
      "Primary CTA": "#d24715",
      "Category Color": "#d24715",
      "Highlight Color": "#d24715",
      "Large Heading Color": "#55685b",
    },
    "Category - Food": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#7D5BAE",
      "Dark Background Secondary": "#7D5BAE",
      "Primary CTA": "#7D5BAE",
      "Category Color": "#7D5BAE",
      "Highlight Color": "#7D5BAE",
    },
    "Category - Health": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#C14E78",
      "Dark Background Secondary": "#C14E78",
      "Primary CTA": "#C14E78",
      "Category Color": "#C14E78",
      "Highlight Color": "#C14E78",
    },
    "Category - Mindfulness": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#6058BC",
      "Dark Background Secondary": "#6058BC",
      "Primary CTA": "#6058BC",
      "Category Color": "#6058BC",
      "Highlight Color": "#6058BC",
    },
    "Category - Movement": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#A35CA2",
      "Dark Background Secondary": "#A35CA2",
      "Primary CTA": "#A35CA2",
      "Category Color": "#A35CA2",
      "Highlight Color": "#A35CA2",
    },
    "Category - Planet": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#378358",
      "Dark Background Secondary": "#378358",
      "Primary CTA": "#378358",
      "Category Color": "#378358",
      "Highlight Color": "#378358",
    },
    "Category - Relationships": {
      "sectionHeading": "Type C",
      "White": "#ffffff",
      "Black": "#222222",
      "Light Background Primary": "#F6F6F6",
      "Light Background Secondary": "#F6F6F6",
      "Dark Background Primary": "#CA4955",
      "Dark Background Secondary": "#CA4955",
      "Primary CTA": "#CA4955",
      "Category Color": "#CA4955",
      "Highlight Color": "#CA4955",
    },
  },
}
export default appTheme
