export const identifyUser = async ({ user, ...props }) => {
  if (window.analytics && user) {
    const { createDate, id, email } = user
    let userId = id
    // if there is no id from the user but there is an email, we can get the user's id from the /identify endpoint
    if (!id) {
      const response = await fetch(`/api/account/identify`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          email,
        }),
        headers: {
          "Content-Type": "application/json",
          "X-API-LANGUAGE": "en/us", // this is a spam header that is required by the API
        },
      })
      if (!response.ok) {
        console.log(response.statusText)
        return
      }
      const data = await response.json()
      if (data.id) {
        ;({ id: userId } = data)
      }
    }
    if (userId) {
      const identifyPayload = {
        email,
        ...(createDate && { createdAt: createDate }),
        ...(props && { ...props }),
      }

      window.analytics.identify(userId, identifyPayload)
    }
  }
}
